<template>
  <div id="pdf">
    <div class="columns">
      <div class="column">
        <template v-if="loading">
          <loading>{{ resultProgress }}</loading>
        </template>
        <template v-else>
          <template v-if="path">
            <iframe :src="path" />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import gql from 'graphql-tag'
import { fetchTerm, fetchUser, fetchAppUrl } from '@/assets/js/app_info.js'

export default {
  name: 'PdfReporter',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Pdf Reporter',
      loading: true,
      path: null,
      termTitle: null,
      termId: null,
      params: {},
      asyncJob: null,
      asyncJobId: null,
      skipQuery: true,
      resultProgress: 'Fetching PDF...',
    }
  },
  apollo: {
    asyncJob: {
      query: gql`
        query asyncJobQuery($id: Int!) {
          asyncJob(id: $id) {
            jobId
            status
            progressCount
            result
          }
        }
      `,
      variables() {
        return { id: parseInt(this.asyncJobId) }
      },
      skip() {
        return this.skipQuery
      },
      pollInterval: 500,
    },
  },
  watch: {
    asyncJob(data) {
      if (data.status === 'Done') {
        this.loading = false
        this.skipQuery = true
        this.path = data.result
      } else {
        this.loading = true
        this.resultProgress = data.status
      }
    },
  },
  methods: {
    loadPDF(path) {
      axios
        .get(`${path}/report`, {
          params: this.params,
        })
        .then((response) => {
          this.skipQuery = false
          this.asyncJobId = response.data.async_job_id
        })
        .catch((error) => {
          console.log(error.message)
        })
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)

    if (!this.$store.getters.getRecord.hasOwnProperty('context')) {
      this.$router.push(`/school/${this.schoolId}/dashboard`)
    } else {
      this.params = this.$store.getters.getRecord
    }

    fetchUser().then((user) => {
      let menus = []

      if (user.role == 'host' || user.role == 'admin') {
        if (
          this.params.context == 'PAYMENT STATUS' ||
          this.params.context == 'SCHOOL FEE RECEIPT'
        ) {
          menus.push({
            name: 'School Fees Info',
            route: `/school/${this.schoolId}/school_fee_info/${this.params.school_class_id}`,
          })
        }
      } else if (user.role == 'student') {
        menus = []
      } else if (user.role == 'parent') {
        menus = []
      }

      this.$store.commit('setSubMenus', menus)
    })

    fetchTerm().then((term) => {
      this.termId = parseInt(term.id)
      this.termTitle = term.session_title
    })

    fetchAppUrl().then((path) => {
      this.loadPDF(path)
    })
  },
}
</script>

<style lang="scss" scoped>
iframe {
  display: block;
  border: none;
  height: 100vh;
  width: 100%;
}
</style>
